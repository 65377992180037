<template>
  <div>
    <general-table
      v-slot="moduleStatus"
      :api-url="APIURL"
      :add-type="addType"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :columns="columns"
      :type="type"
      guard="offers"
      :selectable="false"
    >
      <b-card v-if="moduleStatus.status">
        <div class="not-allow-module">
          <div class="icon text-center">
            <feather-icon
              size="100"
              icon="AlertTriangleIcon"
            />
            <h3 class="pt-2">
              This Module is not active
            </h3>
          </div>
        </div>
      </b-card>
    </general-table>
    <div>
      <b-modal
        ref="view-modal"
        hide-footer
        centered
      >
        <view-offer />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import ViewOffer from './control-offer/ViewOffer.vue'

export default {
  components: { GeneralTable, ViewOffer },
  data() {
    return {
      APIURL: 'offers',
      addType: 'Add Offer',
      addComponentName: 'add-offer',
      editComponent: 'edit-offer',
      type: 'page',
      columns: [
        'select',
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'merchant_name', label: 'Merchant Name' },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'order_type', label: 'Order Type', sortable: true },
        { key: 'price', label: 'Price' },
        { key: 'discount_amount', label: 'Discount Amount' },
        { key: 'discount_type', label: 'Discount Type', sortable: true },
        { key: 'client_cashback', label: 'Client Cashback' },
        { key: 'zerocach_cashback', label: 'DealMart Cashback' },
        { key: 'quantity_limit', label: 'Quantity Limit' },
        { key: 'is_active', label: 'Active' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
