import { ref } from '@vue/composition-api'

export default function offer() {
  const userForm = ref({
    name: '',
    photo: '',
    merchant_id: '',
    description: '',
    type: '',
    order_type: '',
    price: '',
    is_active: '',
    discount_type: '',
    client_cashback_type: '',
    client_cashback: '',
    DealMart_cashback_type: '',
    zerocach_cashback: '',
    quantity_limit: '',
    user_limit: '',
    expire_at: '',
  })

  const viewData = ref({})

  const typeOptions = [
    { text: 'Offer', value: 'offer' },
    { text: 'Cashback', value: 'cashback' },
  ]

  const orderType = [
    { text: 'Store', value: 'store' },
    { text: 'Delivery', value: 'delivery' },
    { text: 'All', value: 'all' },
  ]

  const amountpercentage = [
    { text: 'Amount', value: 'amount' },
    { text: 'Percentage', value: 'percentage' },
  ]

  const resolveCashBackType = role => {
    if (role === 'percentage') return '%'
    if (role === 'amount') return 'amount'

    return 'not'
  }
  const resolveActiveUser = role => {
    if (role === true) return 'Active'

    return 'Not Active'
  }

  return {
    userForm,
    typeOptions,
    amountpercentage,
    orderType,
    viewData,
    resolveCashBackType,
    resolveActiveUser,
  }
}
